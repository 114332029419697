<template>
  <section class="tabs">
    <ul class="tabs__list">
      <Tab
        v-for="(tab, index) in tabs"
        :key="tab.id"
        :tab="tab"
        :tabIndex="index"
        :active="isActive(index)"
        :canDelete="tabs.length > 1"
        @tabEvents="handle($event)"/>
    </ul>
  </section>
</template>

<script>
import Tab from '@/components/dump/TabComponent'

export default {
  name: 'Tabs',
  components: { Tab },
  props: ['tabs', 'activeTab'],
  methods: {
    handle(evt) {
      switch (evt.event) {
        case 'change':
          this.$store.commit('UPDATE_SECTOR', { index: evt.index, property: 'name', value: evt.value })
          break
        case 'delete':
          this.$emit('deleteSector', { index: evt.index })
          break
        case 'switch':
          this.$emit('switchSector', { index: evt.index, action: 'switch' })
          break
      }
    },
    isActive(index) {
      return parseInt(this.activeTab) === index
    }
  }
}
</script>

<style scoped lang="sass">
.tabs
  overflow-y: auto
  &__list
    display: flex
    gap: rem(20)
    width: 100%
</style>
