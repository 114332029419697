<template>
  <li ref="tab" class="tab" :class="{ 'tab--active': active, 'tab--edit': editMode }">
    <div class="tab__wrapper tab__wrapper--no-edit">
      <p @click="handle( { event: 'switch', index: tabIndex })">
        <span class="tab__title">{{ tab.name }}</span>
        <span v-if="tab.system" class="tab__system">{{ title }}</span>
      </p>
      <span
        v-html="getIcon('pencil')"
        class="tab__icon tab__icon--edit"
        @click="handle( { event: 'edit' })"
      ></span>
    </div>
    <div class="tab__wrapper tab__wrapper--edit">
      <div>
        <input
          type="text"
          class="tab__input"
          :id="tab.id"
          :placeholder="tab.name"
          v-model="tab.name"
        />
        <span
          v-html="getIcon('ok')"
          class="tab__icon tab__icon--ok"
          @click="handle( { event: 'change', index: tabIndex, value: tab.name })"
        ></span>
      </div>
      <span
        v-if="canDelete"
        v-html="getIcon('delete')"
        class="tab__icon tab__icon--delete"
        @click="handle({ event: 'delete', index: tabIndex })"
      ></span>
    </div>
  </li>
</template>

<script>
import IconMixin from '@/components/mixin/IconMixin'
import { setInputWidth } from '@/utils/utils'

export default {
  name: 'Tab',
  props: ['tab', 'tabIndex', 'active', 'canDelete'],
  mixins: [IconMixin],
  data: () => ({
    editMode: false,
    inputElement: null
  }),
  mounted() {
    this.inputElement = this.$refs.tab.querySelector('.tab__input')
  },
  methods: {
    handle(evt) {
      if (evt.event !== 'switch') this.editMode = !this.editMode

      if (evt.event === 'edit' && this.editMode) {
        setInputWidth(this.inputElement)
        this.$nextTick(() => this.inputElement.select())
      } else {
        this.$emit('tabEvents', evt)
      }
    }
  },
  computed: {
    title() {
      return this.tab.filling?.title ?? this.tab.system.title
    }
  }
}
</script>

<style scoped lang="sass">
.tab
  display: flex
  @extend %16
  background: $border-color
  border-top-left-radius: $block-radius
  border-top-right-radius: $block-radius
  &__title
    @extend %18
  &__wrapper
    padding: rem(11) rem(20) rem(10)
    cursor: pointer
    &--no-edit
      display: flex
      align-items: center
      > p
        display: flex
        flex-direction: column
        justify-content: center
        margin: 0
        +media((height: (0: rem(36), 768: rem(43))))
        text-align: left
    &--edit
      display: none
      align-items: center
      > div
        position: relative
  &--active
    background: $white
  &__icon
    width: rem(24)
    height: rem(24)
    cursor: pointer
    &--ok
      position: absolute
      top: 50%
      right: 0
      transform: translate(rem(-10), -50%)
    &--edit
      +media((margin-left: (0: rem(4), 768: rem(8))))
    &--delete
      +media((margin-left: (0: rem(4), 768: rem(10))))
  &__input
    margin: 0
    padding: rem(5) rem(57) rem(5) rem(15)
    @extend %18
    border-radius: $block-radius
    border: 1px solid #BABECA
    +media((max-width: (0: rem(30), 340: rem(50), 450: rem(120), 550: none)))
    &:focus
      outline: 1px solid #BABECA
      border: 1px solid #BABECA
  &__system
    padding-top: rem(2)
    margin-right: rem(16)
    @extend %12
    color: $checked-border-color
  &--edit
    .tab__wrapper--no-edit
      display: none
    .tab__wrapper--edit
      display: flex
      padding: rem(15) rem(20)
  &__system, &__title
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    +media((max-width: (0: rem(30), 340: rem(50), 450: rem(120), 550: none)))
</style>
